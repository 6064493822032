import React, {useMemo, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {Translations} from '../../i18n/translations';
import {useTranslation} from 'react-i18next';
import ChangeLanguageButton from '../ChangeLanguageButton';
import {COLORS} from '../../constants/colors';
import {createRightHeaderChoices} from '../../utils/createData';

import Collapse from 'react-bootstrap/Collapse';

const Header = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(992));
  const isMidLargeScreen = useMediaQuery(theme.breakpoints.up(1195));

  const Logo = require('../../assets/logo.png');

  const lagoon40 = useMemo(() => {
    return require('../../assets/menu_icons/lagoon40.png');
  }, []);
  const lagoon42 = useMemo(() => {
    return require('../../assets/menu_icons/lagoon42.png');
  }, []);
  const lagoon43 = useMemo(() => {
    return require('../../assets/menu_icons/lagoon43.jpg');
  }, []);
  const lagoon46 = useMemo(() => {
    return require('../../assets/menu_icons/lagoon46.png');
  }, []);
  const lagoon51 = useMemo(() => {
    return require('../../assets/menu_icons/lagoon51.png');
  }, []);
  const lagoon55 = useMemo(() => {
    return require('../../assets/menu_icons/lagoon55.png');
  }, []);
  const lagoon60 = useMemo(() => {
    return require('../../assets/menu_icons/lagoon60.png');
  }, []);
  const sixty5 = useMemo(() => {
    return require('../../assets/menu_icons/sixty5.png');
  }, []);
  const sixty7 = useMemo(() => {
    return require('../../assets/menu_icons/sixty7.png');
  }, []);
  const seventy7 = useMemo(() => {
    return require('../../assets/menu_icons/seventy7.png');
  }, []);
  const seventy8 = useMemo(() => {
    return require('../../assets/menu_icons/seventy8.png');
  }, []);

  const iconMap = {
    [Translations.LAGOON_40]: lagoon40,
    [Translations.LAGOON_42]: lagoon42,
    [Translations.LAGOON_43]: lagoon43,
    [Translations.LAGOON_46]: lagoon46,
    [Translations.LAGOON_51]: lagoon51,
    [Translations.LAGOON_55]: lagoon55,
    [Translations.LAGOON_60]: lagoon60,
    [Translations.SIXTY_5]: sixty5,
    [Translations.SIXTY_7]: sixty7,
    [Translations.SEVENTY_7]: seventy7,
    [Translations.SEVENTY_8]: seventy8,
  };

  const headerRightChoices = createRightHeaderChoices(t);
  const [backgroundWhite, setBackgroundWhite] = useState(false);
  const [activeIcon, setActiveIcon] = useState('');

  const handleClick = (link) => {
    window.location.href = link;
  };

  const sailingCatamaransArray = [
    {title: Translations.LAGOON_40, onPress: () => {}, icon: ''},
    {title: Translations.LAGOON_42, onPress: () => {}, icon: ''},
    {title: Translations.LAGOON_43, onPress: () => {}, icon: ''},
    {title: Translations.LAGOON_46, onPress: () => {}, icon: ''},
    {title: Translations.LAGOON_51, onPress: () => {}, icon: ''},
    {title: Translations.LAGOON_55, onPress: () => {}, icon: ''},
    {title: Translations.LAGOON_60, onPress: () => {}, icon: ''},
    {title: 'line', onPress: () => {}, icon: ''},
    {title: Translations.SIXTY_5, onPress: () => {}, icon: ''},
    {title: Translations.SEVENTY_7, onPress: () => {}, icon: ''},
  ];

  const powerCatamarans = [
    {title: Translations.SIXTY_7, onPress: () => {}, icon: ''},
    {title: Translations.SEVENTY_8, onPress: () => {}, icon: ''},
  ];

  return (
    <div
      style={{
        display: 'block',
        backgroundColor: backgroundWhite ? 'white' : '',
        width: '100%',
      }}
    >
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div style={{paddingTop: 20}}>
          <Image
            src={Logo}
            width={isLargeScreen ? '124' : '89'}
            height={isLargeScreen ? '56' : '40'}
            alt='logo'
            style={{cursor: 'pointer'}}
            onClick={() => window.open('/', '_top')}
          />
        </div>
        <Navbar
          style={{flex: 1}}
          variant='dark'
          expand='lg'
          className='bg-transparent'
        >
          <div style={{flex: 1}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              {isLargeScreen
                ? headerRightChoices.map((item) => {
                    return (
                      <>
                        <p
                          style={{
                            cursor: 'pointer',
                            color: backgroundWhite ? 'black' : COLORS.WHITE,
                          }}
                          onClick={() => handleClick(item.url)}
                        >
                          {item.name}
                        </p>
                        <div style={{paddingRight: 10}} />
                        <div
                          style={{
                            backgroundColor: backgroundWhite
                              ? 'black'
                              : COLORS.WHITE,
                            width: 1,
                            height: 13,
                            marginTop: 7,
                          }}
                        />
                        <div style={{paddingRight: 10}} />
                      </>
                    );
                  })
                : null}
              <div style={{paddingTop: isLargeScreen ? 0 : 16}}>
                <ChangeLanguageButton backgroundWhite={backgroundWhite} />
              </div>
              <Navbar.Toggle
                type='button'
                style={{border: 0, boxShadow: 'none'}}
                aria-controls='basic-navbar-nav'
              />
            </div>
            <Navbar.Offcanvas
              style={{width: '100%'}}
              id='basic-navbar-nav'
              placement='end'
              backdrop={false}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Offcanvas</Offcanvas.Title>
              </Offcanvas.Header>
            </Navbar.Offcanvas>
            {isLargeScreen ? (
              <Nav>
                <div
                  style={{
                    paddingLeft: 40,
                    // paddingBottom: backgroundWhite ? 20 : 0,
                  }}
                >
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Nav.Link
                      // href={`/${Translations.CATAMARANS}`}
                      style={{
                        color: backgroundWhite ? 'black' : 'white',
                        paddingLeft: 0,
                      }}
                      onMouseOver={() => setBackgroundWhite(true)}
                      onMouseLeave={() => setBackgroundWhite(false)}
                    >
                      <p
                        className='onHoverHeader'
                        style={{
                          fontSize: '15',
                          lineHeight: '1rem',
                          fontFamily: 'sans-serif',
                          listStyleType: 'none',
                          cursor: 'pointer',
                          marginTop: 8,
                          //font:'caption'
                        }}
                      >
                        {t(Translations.CATAMARANS)}
                      </p>
                    </Nav.Link>
                    <Nav.Link
                      href={`/${Translations.DISCOVER_GREECE}`}
                      // className="onHoverHeader"
                      style={{
                        color: backgroundWhite ? 'black' : 'white',
                        paddingLeft: 40,
                        fontSize: '15',
                        lineHeight: '1rem',
                        fontFamily: 'sans-serif',
                        listStyleType: 'none',
                        cursor: 'pointer',
                        marginTop: 8,
                      }}
                    >
                      {t(Translations.DISCOVER_GREECE)}
                    </Nav.Link>
                  </div>
                  <Collapse in={backgroundWhite} className='collapsable'>
                    <div
                      onMouseOver={() => setBackgroundWhite(true)}
                      onMouseLeave={() => setBackgroundWhite(false)}
                      style={{
                        color: backgroundWhite ? 'black' : 'white',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        opacity: backgroundWhite ? 1 : 0,
                      }}
                    >
                      <div>
                        <p
                          style={{
                            paddingBottom: '0.25rem',
                            minWidth: 160,
                            cursor: 'pointer',
                          }}
                          //className="onHoverHeader"
                        >
                          {/* {'YACHT CHAPTER'} */}
                          {t(Translations.SAILING_CATAMARANS)}
                        </p>
                        <ul style={{margin: 0, padding: 0}}>
                          {sailingCatamaransArray.map((item) => {
                            return (
                              <li
                                style={{
                                  fontSize: '15',
                                  lineHeight: '1rem',
                                  fontFamily: 'sans-serif',
                                  listStyleType: 'none',
                                  cursor: 'pointer',
                                  paddingTop: '0.75rem',
                                  //font:'caption'
                                }}
                                key={item.title}
                              >
                                {item.title === 'line' ? (
                                  <hr
                                    style={{
                                      borderTopWidth: '1px',
                                      width: '2.5rem',
                                      color: 'black',
                                      backgroundColor: 'black',
                                    }}
                                  />
                                ) : (
                                  <a
                                    onMouseOver={() =>
                                      setActiveIcon(item.title)
                                    }
                                    onMouseLeave={() => setActiveIcon('')}
                                    style={{
                                      color: backgroundWhite
                                        ? 'black'
                                        : 'white',
                                      textDecoration: 'none',
                                    }}
                                    href={`/ship/${item.title}`}
                                  >
                                    {t(item.title)}
                                  </a>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div
                        style={{
                          paddingLeft: 40,
                          minWidth: 180,
                          cursor: 'pointer',
                        }}
                      >
                        <p>
                          {'NEW YACHT SALES'}
                          {/* {t(Translations.POWER_CATAMARANS)} */}
                        </p>
                        <ul style={{margin: 0, padding: 0}}>
                          {powerCatamarans.map((item) => {
                            return (
                              <li
                                style={{
                                  fontSize: '15',
                                  lineHeight: '1rem',
                                  fontFamily: 'sans-serif',
                                  listStyleType: 'none',
                                  cursor: 'pointer',
                                  paddingTop: '0.75rem',
                                  //font:'caption'
                                }}
                                key={item.title}
                              >
                                <a
                                  onMouseOver={() => setActiveIcon(item.title)}
                                  onMouseLeave={() => setActiveIcon('')}
                                  style={{
                                    color: backgroundWhite ? 'black' : 'white',
                                    textDecoration: 'none',
                                  }}
                                  href={`/ship/${item.title}`}
                                >
                                  {t(item.title)}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div
                        style={{
                          paddingLeft: !isMidLargeScreen ? 30 : 80,
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                          alignContent: 'flex-end',
                        }}
                      >
                        {iconMap[activeIcon] ? (
                          <Image
                            src={iconMap[activeIcon]}
                            style={{paddingLeft: 30, paddingTop: 8}}
                            width={!isMidLargeScreen ? 350 : 480}
                            height={!isMidLargeScreen ? 220 : 280}
                            alt='menuItem'
                          />
                        ) : null}
                      </div>
                    </div>
                  </Collapse>
                </div>
              </Nav>
            ) : null}
          </div>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;
