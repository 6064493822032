import React from "react";
import { Translations } from "../i18n/translations";
import { useTranslation } from "react-i18next";
import { COLORS } from "../constants/colors";

const ChangeLanguageButton = (props) => {
  const {backgroundWhite} = props;
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const handleChangeLanguage = () => {
    const newLanguage = language === "en" ? "el" : "en";
    changeLanguage(newLanguage);
  };
  return (
    <div onClick={handleChangeLanguage}>
      <p
        style={{
          color: backgroundWhite ? "black" : COLORS.WHITE,
          cursor: "pointer",
        }}
      >
        {t(Translations.CHOOSE_LANGUAGE)}
      </p>
    </div>
  );
};

export default ChangeLanguageButton;
