// @ts-nocheck

import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import "react-datepicker/dist/react-datepicker.css";
import "./ReservationPage.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { COUNTRIES } from "../constants/countries";
// import {  Text, Textarea, Select } from 'react-form';
import Header from "../components/navigationBar/Header";

const ReservationPage = () => {
  const theme = useTheme();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const { register, handleSubmit } = useForm();
  const backgroundVideo = require("../assets/backgroundTree.mp4");
  const isSmallScreen = useMediaQuery(theme.breakpoints.up(682));
  const isWebScreen = useMediaQuery(theme.breakpoints.up(992));
  const isWebScreenImage = useMediaQuery(theme.breakpoints.up(1153));

  const onSubmit = (formData) => {
    if (!startDate || !endDate) {
      alert("Please select a reservation period.");
      return;
    }

    // Validate CAPTCHA if needed
    // if (!captchaValue) {
    //   alert("Please complete the CAPTCHA.");
    //   return;
    // }

    const templateParams = {
      ...formData,
      start_date: startDate.toLocaleDateString(),
      end_date: endDate.toLocaleDateString(),
    };

    emailjs
      .send(
        "john_test1",
        "template_4y5hpsr",
        templateParams,
        "k1kS4EKCdl_CMiNTw"
      )
      .then(
        (result) => {
          alert("Reservation request sent successfully!");
        },
        (error) => {
          alert("Failed to send reservation request, please try again later.");
        }
      );
  };

  return (
    <div
      style={
        {
          // backgroundImage: `url(${backgroundImage})`,
          // height: '100vh',
          // maxWidth: 1967,
          // maxHeight: 300,
          // position:'absolute',
          // flex: 1,
          // display: "flex",
          // flexDirection: "column",
        }
      }
    >
      <Header />
      <div className="video-container">
        
        <video autoPlay loop muted className="background-video">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "center",
            marginRight: !isSmallScreen ? 0 : 61,
            marginLeft: !isSmallScreen ? 0 : 61,
            paddingRight: isSmallScreen ? 24 : 32,
            paddingLeft: isSmallScreen ? 24 : 32,
            paddingTop: 80,
            // maxHeight: 1075,
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              flex: 1,
              maxWidth: 664,
              // flexWrap: 'wrap',
            }}
          >
            <h1
              style={{
                color: "black",
                fontFamily: "sans-serif",
                fontSize: 28,
                fontWeight: 300,
                lineHeight: "2.8125rem",
                textAlign: "left",
                maxWidth: 557,
                minWidth: 225,
              }}
            >
              kati
            </h1>
          </div>

    
        </div>
      </div>
      <div className="content">
            <h1>Request Brochure</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label>First Name:</label>
                <input {...register("firstName")} required />
              </div>
              <div className="form-group">
                <label>
                  Last Name:
                  <input {...register("lastName")} required />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Email:
                  <input {...register("email")} required />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Phone:
                  <input {...register("phone")} type="tel" />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Country:
                  <select {...register("country")} required>
                    {COUNTRIES.map((country, index) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label>
                  Comment:
                  <input {...register("comment")} />
                </label>
              </div>
              <div className="form-group">
                <label>
                  The boat(s) of my dreams *:
                  <select {...register("dreamBoat")} required>
                    <option value="" disabled>
                      Select a boat
                    </option>
                    <option value="Lagoon 40">Lagoon 40</option>
                    <option value="Lagoon 42">Lagoon 42</option>
                    <option value="Lagoon 46">Lagoon 46</option>
                    <option value="Lagoon 50">Lagoon 50</option>
                    <option value="Lagoon 52">Lagoon 52</option>
                    <option value="Lagoon 560">Lagoon 560</option>
                    <option value="Lagoon 620">Lagoon 620</option>
                    <option value="Lagoon 630">Lagoon 630</option>
                    <option value="Lagoon Seventy 7">Lagoon Seventy 7</option>
                  </select>
                </label>
              </div>
              <div className="form-group">
                <label>
                  Reservation start Date:
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    required
                  />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Reservation end Date:
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    required
                  />
                </label>
              </div>
              {/* <div className='recaptcha'>
                <ReCAPTCHA
                  sitekey='YOUR_RECAPTCHA_SITE_KEY'
                  onChange={(value) => setCaptchaValue(value)}
                />
              </div> */}
              <button type="submit">Send</button>
            </form>
          </div>
    </div>
  );
};

export default ReservationPage;
