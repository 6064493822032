import React, { useContext } from "react";
import StorageContext from "../context/StorageContext";
import Header from "../components/navigationBar/Header";
import { useTranslation } from "react-i18next";
import { Translations } from "../i18n/translations";
import { Button } from "react-bootstrap";

const Home = () => {
  // const video = require("../assets/lagoon-greece.mp4");
  const Logo = require("../assets/logo.png");
  const { t } = useTranslation();

  const { user } = useContext(StorageContext);
  return (
    <div className="main">
      <div className="overlay" />
      {/* <video className="videoTransform" src={video} autoPlay loop muted /> */}
      <div className="overlayLogo">
        <img src={Logo} className="hideMeLogo" />
      </div>
      <div className="overlayText">
        <h2 className="welcomeText">
          {t(Translations.YOUR_DREAM_DESTINATION)}
        </h2>
        <div style={{paddingTop: 40}}/>
        <Button
          className='hideMeButton'
          style={{
            backgroundColor: "#087d8c",
            borderRadius: "1.75rem",
            paddingLeft: "32px",
            paddingRight: "32px",
            height: 56,
            paddingTop: 8,
            paddingBottom: 8,
            borderColor: "#087d8c",
          }}
        >
          {t(Translations.DISCOVER_GREECE)}
        </Button>
      </div>
      <div className="videoContent">
      <Header />
      </div>
    </div>
  );
};
export default Home;
