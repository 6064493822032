import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Header from '../components/navigationBar/Header';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import Home from './Home';
import './ShipsHoc.css';
import {routes} from '../constants/routes';

const ShipsHoc = () => {
  const params = useParams();
  const shipId = params.id || '';
  const navigate = useNavigate();

  const backgroundImage = require(`../assets/menu_icons/${shipId}item.png`);
  const shapeShipImage = require(`../assets/details/shapeShip.jpg`);
  const backgroundVideo = require('../assets/backgroundTree.mp4');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up(682));
  const isWebScreen = useMediaQuery(theme.breakpoints.up(992));
  const isWebScreenImage = useMediaQuery(theme.breakpoints.up(1153));

  const shipInfo = {
    ['lagoon40']: {
      name: 'LAGOON 40',
      headerDescription: 'A MINI-MAXI CATAMARAN',
      description:
        'A breeze of freedom blows over the Lagoon 40. Live in harmony with the sea and explore the world in complete safety. Your dream destinations have never been so close.',
      body: {
        lengthM: '12.75m',
        lengthI: `41'10"`,
        upwind: '80 m2',
        upwindSq: '861 sq.ft',
        number: 'from 4 to 12 persons from 4 to 6 cabins',
      },
    },
  };
  return (
    <div style={{maxWidth: 1940}}>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          height: '100vh',
          backgroundSize: 'cover',
          maxWidth: 1967,
          maxHeight: 1000,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Header />
        <div
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            display: 'flex',
            width: '100%',
            color: 'white',
            paddingLeft: isSmallScreen ? 150 : 50,
            paddingBottom: isSmallScreen ? 60 : 20,
            flexDirection: 'column',
          }}
        >
          <h1
            style={{
              fontFamily: 'sans-serif',
              fontSize: '2.525rem',
              lineHeight: '2.8125rem',
            }}
          >
            {shipInfo[shipId].name}
          </h1>
          <h1
            style={{
              fontFamily: 'sans-serif',
              fontSize: '2.525rem',
              fontWeight: 'bold',
              lineHeight: '2.8125rem',
            }}
          >
            {shipInfo[shipId].headerDescription}
          </h1>
        </div>
      </div>

      <div className='video-container'>
        <video autoPlay loop muted className='background-video'>
          <source src={backgroundVideo} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'center',
            marginRight: !isSmallScreen ? 0 : 61,
            marginLeft: !isSmallScreen ? 0 : 61,
            paddingRight: isSmallScreen ? 24 : 32,
            paddingLeft: isSmallScreen ? 24 : 32,
            paddingTop: 80,
            // maxHeight: 1075,
          }}
        >
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
              flex: 1,
              maxWidth: 664,
              // flexWrap: 'wrap',
            }}
          >
            <h1
              style={{
                color: 'black',
                fontFamily: 'sans-serif',
                fontSize: 28,
                fontWeight: 300,
                lineHeight: '2.8125rem',
                textAlign: 'left',
                maxWidth: 557,
                minWidth: 225,
              }}
            >
              {shipInfo[shipId].description}
            </h1>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <div style={{width: 155, height: 215, paddingTop: 20}}>
                <svg
                  width='58'
                  height='58'
                  viewBox='0 0 58 58'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle cx='29' cy='29' r='27.5' stroke='#6B6964' />
                  <circle
                    cx='29'
                    cy='29'
                    r='28'
                    stroke='#6B6964'
                    strokeWidth='1.6'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.9268 19.439V37.878L1 29.1439L11.9268 19.439Z'
                    stroke='#6B6964'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M46.0732 19.439V37.878L57 29.1439L46.0732 19.439Z'
                    stroke='#6B6964'
                  />
                  <path d='M11.9268 29.1707L46.5816 29.3415' stroke='#6B6964' />
                  <path d='M28.6586 1V57' stroke='#6B6964' />
                </svg>
                <p
                  style={{
                    color: 'black',
                    fontFamily: 'sans-serif',
                    paddingTop: 20,
                    textAlign: 'left',
                  }}
                >
                  Length overall
                </p>
                <div
                  style={{marginTop: -10, color: '#087D8C', fontWeight: 500}}
                >
                  {shipInfo[shipId].body.lengthM}
                  <br />
                  {shipInfo[shipId].body.lengthI}
                </div>
              </div>
              <div style={{width: 155, height: 215, paddingTop: 20}}>
                <svg
                  width='58'
                  height='58'
                  viewBox='0 0 58 58'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='29'
                    cy='29'
                    r='28'
                    stroke='#6B6964'
                    strokeWidth='1.6'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.6608 2.37042V43.6704H5.86084L22.6608 2.37042Z'
                    stroke='#6B6964'
                    strokeLinejoin='round'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M42.7163 52.9118C42.7163 52.9118 33.8907 43.6704 22.9915 43.9342C22.9915 43.6704 29 37.7559 29 26.9559C29 16.1559 22.9915 2.37042 22.9915 2.37042C22.9915 2.37042 63.1123 19.4053 42.7163 52.9118Z'
                    stroke='#6B6964'
                    strokeLinejoin='round'
                  />
                </svg>
                <p
                  style={{
                    color: 'black',
                    fontFamily: 'sans-serif',
                    paddingTop: 20,
                    textAlign: 'left',
                  }}
                >
                  Upwind sail area
                </p>
                <div
                  style={{marginTop: -10, color: '#087D8C', fontWeight: 500}}
                >
                  {shipInfo[shipId].body.upwind}
                  <br />
                  {shipInfo[shipId].body.upwindSq}
                </div>
              </div>
              <div style={{width: 155, height: 215, paddingTop: 20}}>
                <svg
                  width='58'
                  height='58'
                  viewBox='0 0 58 58'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='29'
                    cy='29'
                    r='28'
                    stroke='#6B6964'
                    strokeWidth='1.6'
                  />
                  <mask
                    id='mask0_47_5060'
                    maskUnits='userSpaceOnUse'
                    x='0'
                    y='0'
                    width='58'
                    height='58'
                  >
                    <circle
                      cx='29'
                      cy='29'
                      r='28'
                      fill='white'
                      stroke='white'
                      strokeWidth='1.6'
                    />
                  </mask>
                  <g mask='url(#mask0_47_5060)'>
                    <circle cx='21.9998' cy='21.9' r='7.7' stroke='#6B6964' />
                    <rect
                      x='9.3999'
                      y='29.6'
                      width='25.2'
                      height='33.6'
                      rx='9.6'
                      stroke='#6B6964'
                    />
                    <circle cx='47.9' cy='21.9' r='7.7' stroke='#6B6964' />
                    <rect
                      x='35.3'
                      y='29.6'
                      width='25.2'
                      height='33.6'
                      rx='9.6'
                      stroke='#6B6964'
                    />
                  </g>
                </svg>
                <p
                  style={{
                    color: 'black',
                    fontFamily: 'sans-serif',
                    paddingTop: 20,
                    textAlign: 'left',
                  }}
                >
                  Number of berths
                </p>
                <div
                  style={{marginTop: -10, color: '#087D8C', fontWeight: 500}}
                >
                  {shipInfo[shipId].body.number}
                </div>
              </div>
              <div style={{width: 400, paddingTop: 40, paddingBottom: 80}}>
                <button
                  onClick={() => navigate(`${routes.RESERVATION}/${shipId}`)}
                  style={{
                    height: '3.5rem',
                    backgroundColor: '#087d8c',
                    color: 'white',
                    borderRadius: '1.75rem',
                    cursor: 'pointer',
                    fontSize: 16,
                  }}
                >
                  Receive your brochure
                </button>
              </div>
            </div>
            {!isWebScreenImage ? (
              <img
                src={backgroundImage}
                style={{maxWidth: 400, maxHeight: 300}}
                width='75%'
                height='75%'
                alt='itemSingle'
              />
            ) : null}
          </div>
          <div>
            {isWebScreenImage ? (
              <img
                src={backgroundImage}
                style={{maxWidth: 400, maxHeight: 300}}
                width='100%'
                height='100%'
                alt='itemSingle'
              />
            ) : null}
          </div>
        </div>
      </div>
      <div style={{backgroundColor: '#546163', color: '#9be7d8'}}>
        <div
          style={{
            paddingTop: 88,
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <div style={{width: 255}}>
            <img
              src={shapeShipImage}
              style={{maxWidth: 480, maxHeight: 480}}
              // width='100%'
              // height='100%'
              alt='itemSingle'
            />
          </div>
          <div>
            <h1>SPECIFICATIONS</h1>
            <div style={{paddingTop: 28}} />
            <ul style={{paddingLeft: 0}}>
              {[
                {title: 'asd', subtitle: '123'},
                {title: 'asd', subtitle: '123'},
              ].map((item) => (
                <div key={item.title}>
                  <div style={{paddingTop: 12}} />
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'space-between',
                      height: 25,
                    }}
                  >
                    <p>{item.title}</p>
                    <p>{item.subtitle}</p>
                  </div>
                  <div style={{paddingBottom: 12}} />
                  <div
                    style={{
                      height: 1,
                      width: '100%',
                      backgroundColor: '#9be7d8',
                    }}
                  />
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShipsHoc;
