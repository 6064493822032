import { routes } from "../constants/routes";
import { Translations } from "../i18n/translations";

export const createRightHeaderChoices = (t) => {
  return [
    { name: t(Translations.EVENTS), url: routes.EVENTS },
    { name: t(Translations.CONTACT), url: routes.CONTACT },
    // { name: t(Translations.SERVICES), url: routes.SERVICES },
    // { name: t(Translations.CLUB_LAGOON), url: routes.CLUB_LAGOON },
  ];
};
