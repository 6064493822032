import React from "react";
import {useParams} from "react-router-dom";

const DiscoverGreece = () => {
    const params = useParams();


    return (
        <div>
            <h1>DiscoverGreece</h1>
        </div>
    );
}
export default DiscoverGreece; 

