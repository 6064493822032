export default {
    welcome: "Welcome",
    chooseLanguage: 'EN',
    events: 'Events',
    contact: 'Contact',
    services: 'Services',
    clubLagoon: 'Club Lagoon',
    yourDreamDestination: 'YOUR DREAM DESTINATION',
    discoverGreece: 'Discover Greece',
    catamarans: 'Catamarans',
    sailingCatamarans: 'Sailing catamarans',
    powerCatamarans: 'Power catamarans',
    lagoon40: 'LAGOON 40',
    lagoon42: 'LAGOON 42',
    lagoon43: 'LAGOON 43',
    lagoon46: 'LAGOON 46',
    lagoon51: 'LAGOON 51',
    lagoon55: 'LAGOON 55',
    lagoon60: 'LAGOON 60',
    sixty5: 'SIXTY 5',
    sixty7: 'SIXTY 7',
    seventy7: 'SEVENTY 7',
    seventy8: 'SEVENTY 8',
}