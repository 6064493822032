import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "./App.css";
import Header from "./components/navigationBar/Header";
import Footer from "./components/Footer";
import Home from "./navigation/Home";
import ShipsHoc from "./navigation/ShipsHoc";
import { routes } from "./constants/routes";
import StorageContext from "./context/StorageContext";
import NotFound from "./navigation/NotFound";
import DiscoverGreece from "./navigation/DiscoverGreece";
import ReservationPage from "./navigation/ReservationPage";

function App() {
  const contextValue = {theme: "light", language: "gr"};
  return (
    <div >
    <StorageContext.Provider value={contextValue}>
      <BrowserRouter>
        {/* <Header /> */}
        <Routes>
          <Route index path={routes.HOME} element={<Home />} />
          <Route path="/catamarans" element={<ShipsHoc />} />
          <Route path="/discoverGreece" element={<DiscoverGreece />} />
          <Route exact path="/ship/:id" element={<ShipsHoc />} />
          <Route path="/reservation/*" element={<ReservationPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* <Footer/> */}
      </BrowserRouter>
    </StorageContext.Provider>
    </div>
  );
}

export default App;
