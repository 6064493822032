import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './Translations/en';
import el from './Translations/el';

const resources = {en: {translation: {...en}}, el: {translation: {...el}}};

i18n.use(initReactI18next).init({
  resources,
  lng: 'el',
});
